header {
  text-align: center;
  .welcome-h1 {
    margin: .15em;
    font-weight: 900;
    font-size: 3.2em;
    text-shadow: -1.5px -1.5px 2px #3a3a3a;
  }
  .welcome-h3 {
    margin: .25em;
    font-weight: 500;
    font-size: 2.5em;
    text-shadow: -1.0px -1.0px 1.5px #3a3a3a;
    color: $light-blue;
  }
}

.navbar {
  border-radius: 0px;
  border-top: .05em solid dimgrey;
  border-bottom: .05em solid dimgrey;
  .navbar-toggle {
    .icon-bar {
      background-color: $dark-blue;
    }

  }
  .navbar-nav {
    @extend .nav-justified;
    padding-left: 20%;
    padding-right: 20%;
    li{
      font-size: 1.2em;
      a {
        color: $dark-blue;
      }
      a:hover {
        background-color: $light-orange;
        color: $white;
      }
      a:visited {
        background-color: inherit;
      }
    }


  }
}
content {
  .portfolio-header {
    text-align: center;
    margin: .25em;
    font-weight: 600;
    font-size: 3em;
    text-shadow: -1.0px -1.0px 1.5px #3a3a3a;
    color: $dark-orange;
  }
  ul.portfolio-nav {
    font-size: 1.1em;
    li.active {
      a:focus{
        background-color: $light-blue;
        color: $white;
      }
      a {
        background-color: $light-blue;
        color: $white;
      }
      a:hover {
        color: $white;
        background-color: $light-orange;
      }
    }
    li {
      a {
        color: $dark-orange;
      }
      a:hover {
        color: $white;
        background-color: $light-orange;
      }

    }
  }
  .portfolio-entry {
    font-size: 1.1em;
    h3 {
      margin: .25em;
      font-weight: 500;
      text-shadow: -1.0px -1.0px 1.5px #3a3a3a;
      color: $dark-blue;
    }
    img {
      margin-right: 1.25em;
      padding-left: 0px;
    }
  }
  .portfolio-entry-divider {
    border-top: .05em solid dimgrey;
    margin-top: 2.5em;
  }
}

.welcome-footer {
  margin-top: 1.5em;
  text-align: center;
}