

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Typography
@import "typography";

// Colors
@import "colors";

// Pages
@import "pages/home.scss";

body {
  background-color: $white;
  font-family: $font-family;
  color: $dark-blue;
}

